/* variables */
:root {
  --headerHeight: 40px;
}

/* Main */
.App {
  text-align: center;
}

.row {
  display: flex;
}

.DispatchAndResourcesColumn {
  flex: 30%;
  margin-left: 20px;
}

.MapColumn {
  flex: 60%;
  margin-right: 20px;
}

.MajorComponent {
  border: 1px solid rgb(179, 179, 179);
  border-radius: 7px;
  background-color: #f8f9fa;
  margin: 5px;
}

/* Header */
.Header {
  height: var(--headerHeight);
}

.navbar {
  height: var(--headerHeight);
}

.settings-dropdown {
  color: black;
}

/* Dispatch */
.DispatchInputFieldsBox {
  margin-left: 20px;
  margin-right: 20px;
}

.UnitDispatchCard {
  margin: 2px;
  font-size: 12px;
  border: 1px solid gray;
  border-radius: 2px;
  display: flex;
  height: 18px;
  line-height: 18px;
  text-align: center;
}

.UnitDispatchCardText {
  text-indent: 2px;
}

.removeUnitFromDispatch {
  color: red;
  font-weight: bold;
  font-size: 10px;
  height: 100%;
  margin: 0px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.DispatchInput {
  margin-top: 6px;
  display: flex;
}

.DispatchButton {
  margin: 8px;
  background-color: white;
  color: rgb(95, 200, 135);
  border: 2px solid rgb(95, 200, 135);
  border-radius: 15px;
}

/* Resources */
.ResourcesTable {
  height: calc(100vh - 40px - 5px - 270px);
  text-align: center;
  font-size: 10px;
}

/* -------
    Map 
------- */
.Map {
  height: calc(100vh - 40px - 30px);
  position: relative;
  border-radius: 0px;
}

.mapboxgl-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.mapClick-button {
  padding: 5px;
  margin: 3px;
}

/* vehicles */
.vehicle-marker {
  background-image: url("images/icon.jpg");
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.vehicle-marker-unavailable {
  background-image: url("images/icon.jpg");
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.6;
}

.vehicle-location-popup-card {
  border: none;
  margin: 0px;
  padding: 0px;
}

.vehicle-popup {
  margin: 0px;
  padding: 0px;
}

/* Alerts */

.alertMarker {
  background-size: cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.scheduledRoadOutage {
  opacity: 0.5;
  border-color: black;
  border-style: solid;
}

.alert-hazard {
  background-image: url("images/alerts/hazard.png");
}

.alert-flooding {
  background-image: url("images/alerts/flooding.png");
}

.alert-edit {
  background-image: url("images/alerts/roadUpdate.png");
}

.alert-traffic {
  background-image: url("images/alerts/traffic.png");
}

.alert-accident {
  background-image: url("images/alerts/accident.png");
}

.alert-repair {
  background-image: url("images/alerts/repair.png");
}

.alert-button {
  padding: 5px;
  margin: 3px;
}

.postAlert-button {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-size: contain;
  border-width: 0.5px;
}

.postAlert-button:hover {
  height: 37px;
  width: 37px;
}

.alert-type-selector {
  text-align: center;
}

.alertSelectorParent {
  text-align: center;
}

.react-datepicker {
  width: 350px;
}
